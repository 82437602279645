#vision {}


#visionCards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 100px 10% 60px 10%;
}

.visionCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    padding: 50px;
    border-right: 3px solid #3f5aa0;
}

.visionCardRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    padding: 50px;
}

.visionCardIcon {
    margin-bottom: 10px;
    font-size: 40px;
    color: #3f5aa0;
}

.visionCardTitle {
    margin-bottom: 30px;
    font-weight: 550;
    font-size: 24px;
    color: var(--sectionLightText);
}

.visionCardText {
    font-size: 18px;
    color: var(--sectionLightText);
}

.visionSmartphoneCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    padding: 50px;
}
#adminUserDate {
    margin: 0 10% 40px 10%;
}

.adminUserDateLeft {
    text-align: right;
}

.adminUserDateRight {
    text-align: left;
}

#adminUserUpdateForm {
    margin: 0 10% 40px 10%;
}
.devOpsSectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.devOpsSectionLightText {
    margin: 0 0 30px 10%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

#devOpsSectionDark {
    margin: 500px 0 400px 0;
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

#devOpsSectionDarkText {
    margin: 0 10% 30px 0;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.devOpsTextParagraph {
    margin-top: 15px;
    margin-bottom: 15px;
}

.devOpsImage {
    border-radius: 10px;
}

.devOpsIcons {
    margin: 50px 15% 0 15%;
}

.devOpsSmartphoneSectionLight {
    padding: 25px 0 0 0;
    background: var(--sectionLightBackground);
}

.devOpsSmartphoneSectionLightText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.devOpsSmartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 50px 0 50px 0;
    background: var(--sectionDarkBackground);
}

.devOpsSmartphoneSectionDarkText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.devOpsSmartphoneImage {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}
.modal-header {
    background: var(--modal) !important;
    color: var(--modalHeaderText) !important;
}

.modal-title {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    text-align: center !important;
}

.modal-body {
    background: var(--modalBodyBackground) !important;
    font-size: 20px !important;
    color: var(--modalBodyText) !important;
}

.modal-footer {
    justify-content: center !important;
    align-content: center !important;
    background: var(--modal) !important;
    color: var(--modalFooterText) !important;
}

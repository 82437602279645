.sectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.sectionLightTitle {
    margin-bottom: 80px;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: var(--sectionLightTitle);
}

.sectionLightTitleIcon {
    margin-right: 20px;
}

.sectionDark {
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

.sectionDarkTitle {
    margin-bottom: 80px;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: var(--sectionDarkTitle);
}

.sectionDarkTitleIcon {
    margin-right: 20px;
}

.sectionLightSmartphoneTitle {
    margin-bottom: 60px;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    color: var(--sectionLightTitle);
}

.sectionLightSmartphoneTitleIcon {
    margin-right: 20px;
}

.sectionDarkSmartphoneTitle {
    margin-bottom: 60px;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    color: var(--sectionDarkTitle);
}

.sectionDarkSmartphoneTitleIcon {
    margin-right: 20px;
}
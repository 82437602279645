#header {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    margin: 0 auto;
    text-align: center;
    background: var(--headerBackground);
}

#header:before,
#header:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: " ";
    transform-origin: center;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 50;
    height: 2560px;
    width: 2560px;
    margin-top: -1280px;
    margin-left: -1280px;
}

#header:before {
    background-image: url(../assets/images/headerCircle1.png);
    background-size: 100% auto;
    -webkit-animation: rotate 30s infinite linear;
    -moz-animation: rotate 30s infinite linear;
    -o-animation: rotate 30s infinite linear;
    animation: rotate 30s infinite linear;
}

#header:after {
    background-image: url(../assets/images/headerCircle2.png);
    background-size: 100% auto;
    -webkit-animation: rotate 80s infinite linear;
    -moz-animation: rotate 80s infinite linear;
    -o-animation: rotate 80s infinite linear;
    animation: rotate 80s infinite linear;
}

#header #headerText {
    position: relative;
    z-index: 100;
    width: 785px;
}

#header #headerText #headerTextTitle {
    position: relative;
    margin: 30px 0;
    border-top: 1px solid var(--headerTextLine);
    border-bottom: 1px solid var(--headerTextLine);
    line-height: 180px;
    text-transform: uppercase;
    font-size: 100px;
    color: var(--headerTextTitle);
}

#header #headerText #headerTextTitleSmartphone {
    position: relative;
    margin: 30px 0;
    border-top: 1px solid var(--headerTextLine);
    border-bottom: 1px solid var(--headerTextLine);
    line-height: 180px;
    text-transform: uppercase;
    font-size: 60px;
    color: var(--headerTextTitle);
}

@supports (-webkit-background-clip: text) {
    #header #headerText #headerTextTitle {
        background: radial-gradient(ellipse at center, var(--headerTextTitle) 0%, var(--headerTextTitle) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

#header #headerText {
    text-transform: uppercase;
    font-weight: 600;
}

#header #headerText #headerTextSubtitle {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    color: var(--headerTextSubtitle);
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
#adminContactContainer {
    overflow: hidden;
    width: 95%;
    margin: 30px 10% 20px 0;
    padding: 30px 0 0px 0;
    border-radius: 20px;
    background: var(--adminBackground);
    transition: all 0.3s;
}

#adminContactTitle {
    margin-bottom: 20px;
    font-weight: 550;
    font-size: 30px;
    color: var(--adminTitle);
}

#adminContactTitleIcon {
    font-size: 28px;
    margin-right: 20px;
}

#adminContact {
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-color: var(--scrollbar) var(--scrollbarBackground);
    scrollbar-width: thin;
    height: 450px;
    margin: 10px 10px 30px 0px;
    padding: 0 5px 0 10px;
    text-align: left;
    font-weight: 550;
    font-size: 18px;
    color: var(--adminText);
}

#adminContactHeaderIcon {
    font-size: 22px;
    margin-right: 10px;
}

.adminContactNotView {
    height: 10px;
    width: 10px;
    margin: 0 8px 3px 0;
    color: var(--adminContactNotView);
}

.adminContactView {
    height: 10px;
    width: 10px;
    margin: 0 8px 0 0;
    color: var(--adminContactView);
}

.adminContactName {
    font-size: 16px;
}

.adminContactDate {
    margin-left: 18px;
    font-size: 12px;
}

.contactMessage {
    font-size: 16px;
}

.contactMessageInfo {
    font-size: 14px;
}

.contactMessageInfoIcon {
    margin-right: 10px;
}
.switchSelectorContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.switchSelector {
    height: 30px;
    width: 90%;
}
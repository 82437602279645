.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonPrimary {
    cursor: pointer;
    transition: all 0.3s !important;
    height: 30px !important;
    padding: 2px 30px 0 30px !important;
    padding-top: 2px !important;
    border-radius: 20px !important;
    font-weight: 550 !important;
    font-size: 15px !important;
    text-align: center;
    border: 1px solid var(--buttonPrimaryText) !important;
    background: var(--buttonPrimary) !important;
    color: var(--buttonPrimaryText) !important;
}

.buttonPrimary:hover {
    padding: 2px 40px 0 40px !important;
    background: var(--buttonPrimaryText) !important;
    color: var(--buttonPrimary) !important;
}

.buttonSecondary {
    cursor: pointer;
    transition: all 0.3s !important;
    height: 30px !important;
    padding: 2px 30px 0 30px !important;
    border-radius: 20px !important;
    font-weight: 550 !important;
    font-size: 16px !important;
    text-align: center;
    margin-bottom: 20px !important;
    background: var(--buttonSecondary) !important;
    color: var(--buttonSecondaryText) !important;
}

.buttonSecondary:hover {
    padding: 2px 40px 0 40px !important;
    background: var(--buttonSecondaryText) !important;
    color: var(--buttonSecondary) !important;
}

.buttonSuccess {
    cursor: pointer;
    transition: all 0.3s !important;
    height: 30px !important;
    padding: 2px 30px 0 30px !important;
    padding-top: 2px !important;
    border-radius: 20px !important;
    font-weight: 550 !important;
    font-size: 15px !important;
    text-align: center;
    border: 1px solid var(--buttonSuccessText) !important;
    background: var(--buttonSuccess) !important;
    color: var(--buttonSuccessText) !important;
}

.buttonSuccess:hover {
    padding: 2px 40px 0 40px !important;
    background: var(--buttonSuccessText) !important;
    color: var(--buttonSuccess) !important;
}

.buttonWarning {
    cursor: pointer;
    transition: all 0.3s !important;
    height: 30px !important;
    padding: 2px 30px 0 30px !important;
    padding-top: 2px !important;
    border-radius: 20px !important;
    font-weight: 550 !important;
    font-size: 15px !important;
    text-align: center;
    border: 1px solid var(--buttonWarningText) !important;
    background: var(--buttonWarning) !important;
    color: var(--buttonWarningText) !important;
}

.buttonWarning:hover {
    padding: 2px 40px 0 40px !important;
    background: var(--buttonWarningText) !important;
    color: var(--buttonWarning) !important;
}

.buttonDanger {
    cursor: pointer;
    transition: all 0.3s !important;
    height: 30px !important;
    padding: 2px 30px 0 30px !important;
    padding-top: 2px !important;
    border-radius: 20px !important;
    font-weight: 550 !important;
    font-size: 15px !important;
    font-size: 15px !important;
    text-align: center;
    border: 1px solid var(--buttonDangerText) !important;
    background: var(--buttonDanger) !important;
    color: var(--buttonDangerText) !important;
}

.buttonDanger:hover {
    padding: 2px 40px 0 40px !important;
    background: var(--buttonDangerText) !important;
    color: var(--buttonDanger) !important;
}
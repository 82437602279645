.hostingSectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.hostingSectionLightText {
    margin: 0 0 30px 10%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

#hostingSectionDark {
    margin: 500px 0 400px 0;
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

#hostingSectionDarkText {
    margin: 0 10% 30px 0;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.hostingTextParagraph {
    margin-top: 15px;
    margin-bottom: 15px;
}

.hostingImage {
    border-radius: 10px;
}

.hostingIcons {
    margin: 50px 15% 0 15%;
}

.hostingSmartphoneSectionLight {
    padding: 25px 0 0 0;
    background: var(--sectionLightBackground);
}

.hostingSmartphoneSectionLightText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.hostingSmartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 50px 0 50px 0;
    background: var(--sectionDarkBackground);
}

.hostingSmartphoneSectionDarkText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.hostingSmartphoneImage {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}
#contactForm {
    margin: 0 20% 0 20%;
}

#contactFormSuccess {
    height: 200px;
    font-weight: 600;
    font-size: 24px;
    color: var(--contactFormSuccess);
}

#contactSmartphoneForm {
    margin: 0 5% 0 5%;
}
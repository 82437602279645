:root {

    --background: #dde2eb;

    --scrollbar: #151c27;
    --scrollbarBackground: #364153;

    --sectionLightBackground: #dde2eb;
    --sectionLightTitle: #243e53;
    --sectionLightText: #293553;
    --sectionDarkBackground: #1b3246;
    --sectionDarkTitle: #dde2eb;
    --sectionDarkText: #dfe2e9;

    --headerBackground: #0d1117;
    --headerTextTitle: #4a5ab6;
    --headerTextSubtitle: #8c98db;
    --headerTextLine: #ffffff;

    --navBackground: #151c27;
    --navLeftText: #cddeec;
    --navLeftTextActive: #e2e9f0;
    --navLinkBackground: #cddeec;
    --navLinkText: #1d2d3a;
    --navLinkBackgroundActive: #e2e9f0;
    --navCloseIcon: #ebc6c6;

    --navSocialIcon: #cddeec;
    --navSocialIconActive: #e2e9f0;

    --navSmartphoneTitle: #cddeec;
    --navSmartphoneBurgerIcon: #cddeec;
    --navSmartphoneCloseIcon: #ebc6c6;
    --navSmartphoneLinkText: #cddeec;

    --offersBackground: #c2cbe4;
    --offersBackgroundActive: #d6dbe9;
    --offersTitle: #293553;
    --offersText: #293553;

    --contactFormSuccess: #dde2eb;

    --footerLinkText: #cddeec;
    --footerActiveLinkBackground: #243e53;
    --footerActiveLinkText: #e2e9f0;
    --footerBackground: #0d1117;

    --adminBackground: #151c27;
    --adminTitle: #cddeec;
    --adminText: #cddeec;

    --adminUserCompanyEmployee: #50678d;

    --adminContactView: #4d5d75;
    --adminContactNotView: #58815a;

    --adminProjectItem: #242f41;
    --adminProjectItemText: #cddeec;
    --adminProjectItemActive: #303e55;
    --adminProjectItemActiveText: #cddeec;
    --adminProjectClose: #bb4040;

    --inputText: #ffffff;
    --inputTextText: #215480;
    --inputTextSuccess: #ffffff;
    --inputTextSuccessText: #58815a;
    --inputTextError: #ffffff;
    --inputTextErrorText: #bb4040;
    --inputTextarea: #ffffff;
    --inputTextareaText: #215480;
    --inputTextareaSuccess: #ffffff;
    --inputTextareaSuccessText: #466948;
    --inputTextareaError: #ffffff;
    --inputTextareaErrorText: #bb4040;
    --inputSelect: #dadada;
    --inputSelectText: #215480;
    --inputSelectSuccess: #ffffff;
    --inputSelectSuccessText: #58815a;
    --inputSelectError: #ffffff;
    --inputSelectErrorText: #bb4040;
    --inputSearchBackground: #242f41;
    --inputSearchText: #cddeec;


    --badgePrimary: #215480;
    --badgePrimaryText: #ffffff;
    --badgeSecondary: #8b8b8b;
    --badgeSecondaryText: #ffffff;
    --badgeSuccess: #58815a;
    --badgeSuccessText: #ffffff;
    --badgeWarning: #cd8a34;
    --badgeWarningText: #ffffff;
    --badgeDanger: #bb4040;
    --badgeDangerText: #ffffff;

    --buttonPrimary: #ffffff;
    --buttonPrimaryText: #215480;
    --buttonSecondary: #242f41;
    --buttonSecondaryText: #cddeec;
    --buttonSuccess: #ffffff;
    --buttonSuccessText: #58815a;
    --buttonWarning: #ffffff;
    --buttonWarningText: #cd8a34;
    --buttonDanger: #ffffff;
    --buttonDangerText: #bb4040;
    --accordionHeaderBackground: #;

    --accordionHeaderBackground: #242f41;
    --accordionHeaderText: #cddeec;
    --accordionHeaderBackgroundActive: #303e55;
    --accordionBodyBackground: #3d4f6b;

    --modal: #151c27;
    --modalHeaderText: #cddeec;
    --modalBodyBackground: #3d4f6b;
    --modalBodyText: #000000;
    --modalFooterText: #ffffff;

    --tabs: #242f41;
    --tabsTitle: #242f41;
    --tabsTitleText: #cddeec;
    --tabsTitleActive: #303e55;
    --tabsTitleActiveText: #cddeec;
    --tabsBody: #303e55;
    --tabsBodyText: ;

}
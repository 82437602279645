.usText {
    margin: 0 15% 30px 15%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.usSmartphoneText {
    margin: 0 20px 30px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}
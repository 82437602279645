.offersRow {
    display: flex;
    flex-direction: row;
}

.offersColBadge {
    margin: -25px 0 0 80%;
}

.offersColLeft {
    transition: all 0.3s;
    cursor: pointer;
    height: 320px;
    margin: 30px 2.5% 50px 2.5%;
    padding: 10px;
    border-radius: 20px;
    background: var(--offersBackground);
}

.offersColLeft:hover {
    margin: -10px 0 50px 5%;
    background: var(--offersBackgroundActive);
    box-shadow: 0 0 10px white;
}

.offersColRight {
    transition: all 0.3s;
    cursor: pointer;
    height: 320px;
    margin: 30px 2.5% 50px 2.5%;
    padding: 10px;
    border-radius: 20px;
    background: var(--offersBackground);
}

.offersColRight:hover {
    margin: -10px 5% 50px 0;
    background: var(--offersBackgroundActive);
    box-shadow: 0 0 10px white;
}

.offersTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 550;
    font-size: 30px;
    color: var(--offersTitle);
}

.offersTitleIcon {
    font-size: 28px;
    margin-right: 20px;
}

.offersText {
    margin: 0 10px 0 20%;
    padding: 20px 10px 0 10px;
    text-align: left;
    font-weight: 550;
    font-size: 18px;
    color: var(--offersText);
}

#offerWebsite {
    background-image: url(../../../assets/images/offer/offerWebsiteBackground.jpg);
    background-size: 100% auto;
}

#offerApp {
    background-image: url(../../../assets/images/offer/offerAppBackground.jpg);
    background-size: 100% auto;
}

#offerHosting {
    background-image: url(../../../assets/images/offer/offerHostingBackground.jpg);
    background-size: 100% auto;
}

#offerHome {
    background-image: url(../../../assets/images/offer/offerHomeBackground.webp);
    background-size: 100% auto;
}

#offerDevops {
    background-image: url(../../../assets/images/offer/offerDevopsBackground.webp);
    background-size: 100% auto;
}

#offerVideo {
    background-image: url(../../../assets/images/offer/offerVideoBackground.jpg);
    background-size: 100% auto;
}

.offersSmartphoneColBadge {
    margin: -25px 0 0 40%;
}

.offersSmartphoneCol {
    height: 320px;
    margin: 50px 5% 80px 5%;
    padding: 10px;
    border-radius: 20px;
    background: var(--offersBackground);
}

.offersSmartphoneText {
    margin: 0 10px 0 10px;
    padding: 10px 10px 0 10px;
    text-align: left;
    font-weight: 550;
    font-size: 18px;
    color: var(--offersText);
}
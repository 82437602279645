.tab {
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    background: var(--tabs);
}

.nav-link {
    border-radius: 0px !important;
    border-bottom: 1px solid var(--tabsTitleActive) !important;
    background: var(--tabsTitle) !important;
    color: var(--tabsTitleText) !important;
    font-family: cursive !important;
}

.nav-link:hover {
    background: var(--tabsTitleActive) !important;
}

.nav-link.active {
    background: var(--tabsTitleActive) !important;
    color: var(--tabsTitleActiveText) !important;
}

.tab-pane {
    padding: 10px 20px 10px 20px !important;
    background: var(--tabsBody) !important;
    border-radius: 0 10px 10px 0 !important;
    text-align: left !important;
    color: var(--tabsBodyText) !important;
}

.tabHorizontal {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    min-height: 80%;
    border-radius: 10px!important;
    margin-bottom: 20px;
    background: var(--tabs);
}

.tabHorizontalHome {
    background: var(--tabsTitleActive) !important;
}

.tabHorizontalLink {
    width: 40%;
}

.tabHorizontalBody {
    width: 60%;
}
.websiteSectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.websiteSectionLightText {
    margin: 0 0 30px 10%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

#websiteSectionDark {
    margin: 500px 0 400px 0;
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

#websiteSectionDarkText {
    margin: 0 10% 30px 0;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.websiteTextParagraph {
    margin-top: 15px;
    margin-bottom: 15px;
}

.websiteImage {
    border-radius: 10px;
}

.websiteIcons {
    margin: 50px 15% 0 15%;
}

.websiteSmartphoneSectionLight {
    padding: 25px 0 0 0;
    background: var(--sectionLightBackground);
}

.websiteSmartphoneSectionLightText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.websiteSmartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 50px 0 50px 0;
    background: var(--sectionDarkBackground);
}

.websiteSmartphoneSectionDarkText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.websiteImageSmartphone {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}
.popover {
    width: 500px;
}

#adminUserCompanyDate {
    margin: 0 10% 40px 10%;
}

.adminUserCompanyDateLeft {
    text-align: right;
}

.adminUserCompanyDateRight {
    text-align: left;
}

#adminUserCompanyEmployee {
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: var(--adminUserCompanyEmployee)
}

#adminUserCompanyEmployeeForm {
    margin: 50px 10% 40px 10%;
}

.adminUserCompanyEmployeeAvatar {
    transition: all 0.3s;
    cursor: pointer;
    margin: 10px 10px 0 10px;
    opacity: 0.8;
}

.adminUserCompanyEmployeeAvatar:hover {
    margin: 5px 10px 0 10px;
    opacity: 1;
}
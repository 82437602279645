.input-group {
    margin-bottom: 20px !important;
}

.form-control:focus {
    box-shadow: 0 0 3px !important;
}

.inputTextareaIcon {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    background: var(--inputTextarea) !important;
    color: var(--inputTextareaText) !important;
}

.inputTextareaIconSuccess {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextareaSuccessText) !important;
    background: var(--inputTextareaSuccess) !important;
    color: var(--inputTextareaSuccessText) !important;
}

.inputTextareaIconError {
    z-index: 1 !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextareaErrorText) !important;
    background: var(--inputTextareaError) !important;
    color: var(--inputTextareaErrorText) !important;
}

.inputTextarea {
    z-index: 0 !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    background: var(--inputTextarea) !important;
    color: var(--inputTextareaText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextareaSuccess {
    z-index: 0 !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    border: 2px solid var(--inputTextareaSuccessText) !important;
    background: var(--inputTextareaSuccess) !important;
    color: var(--inputTextareaSuccessText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextareaError {
    z-index: 0 !important;
    padding-left: 60px !important;
    border-radius: 20px !important;
    border: 2px solid var(--inputTextareaErrorText) !important;
    background: var(--inputTextareaError) !important;
    color: var(--inputTextareaErrorText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}
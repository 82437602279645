#admin {
    padding-top: 50px;
    background: var(--sectionLightBackground);
}

#adminTitle {
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: var(--sectionLightTitle);
}
.form-control:focus {
    box-shadow: 0 0 3px !important;
}

.inputSelectIcon {
    z-index: 1 !important;
    height: 40px !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    background: var(--inputSelect) !important;
    color: var(--inputSelectText) !important;
}

.inputSelectIconSuccess {
    z-index: 1 !important;
    height: 40px !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputSelectSuccessText) !important;
    background: var(--inputSelectError) !important;
    color: var(--inputSelectSuccessText) !important;
}

.inputSelectIconError {
    z-index: 1 !important;
    height: 40px !important;
    width: 50px !important;
    margin-right: -50px;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputSelectErrorText) !important;
    background: var(--inputSelectError) !important;
    color: var(--inputSelectErrorText) !important;
}

.inputSelect {
    height: 40px !important;
    margin-top: -40px !important;
    margin-left: 49px !important;
    margin-bottom: 30px !important;
    border-radius: 0 20px 20px 0 !important;
    background: var(--inputSelect) !important;
    font-size: 16px !important;
    font-weight: 550 !important;
    color: var(--inputSelectText) !important;
}

.inputSelectLabelIcon {
    margin-right: 10px;
}
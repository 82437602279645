.input-group {
    margin-bottom: 30px;
}

.form-control:focus {
    box-shadow: 0 0 3px !important;
}

.inputTextIcon {
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
}

.inputTextIconSuccess {
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextSuccessText) !important;
}

.inputTextIconError {
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    padding-left: 15px !important;
    border-radius: 20px 0 0 20px !important;
    border: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
}

.inputText {
    z-index: 0 !important;
    height: 30px !important;
    border-radius: 0 20px 20px 0 !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextSuccess {
    z-index: 0 !important;
    height: 30px !important;
    border-radius: 0 20px 20px 0 !important;
    border: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextSuccess) !important;
    color: var(--inputTextSuccessText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextError {
    z-index: 0 !important;
    height: 30px !important;
    border-radius: 0 20px 20px 0 !important;
    border: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
    font-size: 14px !important;
    font-weight: 550 !important;
}

.inputTextVisible {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: -50px !important;
    padding-left: 15px !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
}

.inputTextVisibleSuccess {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: -50px !important;
    padding-left: 15px !important;
    border-top: 2px solid var(--inputTextSuccessText) !important;
    border-left: 2px solid var(--inputTextSuccessText) !important;
    border-bottom: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextSuccessText) !important;
}

.inputTextVisibleError {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: -50px !important;
    padding-left: 15px !important;
    border-top: 2px solid var(--inputTextErrorText) !important;
    border-left: 2px solid var(--inputTextErrorText) !important;
    border-bottom: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
}

.inputTextGenerate {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: 0px !important;
    padding-left: 15px !important;
    border-radius: 0 20px 20px 0 !important;
    background: var(--inputText) !important;
    color: var(--inputTextText) !important;
}

.inputTextGenerateSuccess {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: 0 !important;
    padding-left: 15px !important;
    border-radius: 0 20px 20px 0 !important;
    border: 2px solid var(--inputTextSuccessText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextSuccessText) !important;
}

.inputTextGenerateError {
    cursor: pointer;
    z-index: 1 !important;
    height: 30px !important;
    width: 50px !important;
    margin-left: 0 !important;
    padding-left: 15px !important;
    border-radius: 0 20px 20px 0 !important;
    border: 2px solid var(--inputTextErrorText) !important;
    background: var(--inputTextError) !important;
    color: var(--inputTextErrorText) !important;
}
#cgu {
    text-align: left;
    
}

.CGUSubtitle {
    font-weight: 550;
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 10%;
}

.CGUText {
    margin: 0 0 30px 12%;

}
#adminUserContainer {
    overflow: hidden;
    width: 95%;
    margin: 30px 5% 20px 5%;
    padding: 30px 0 0px 0;
    border-radius: 20px;
    background: var(--adminBackground);
    transition: all 0.3s;
}

/* #adminUserFullscreenContainer {
    overflow: hidden;
    width: 90%;
    margin: 30px 5% 20px 5%;
    padding: 30px 0 0px 0;
    border-radius: 20px;
    background: var(--adminBackground);
    transition: all 0.3s;
} */

#adminUserFullscreenIcon {
    transition: all 0.3s;
    cursor: pointer;
    float: right;
    margin: -10px 20px 0 0;
    opacity: 0.8;
    font-size: 25px;
    color: var(--adminTitle);
}

#adminUserFullscreenIcon:hover {
    opacity: 1;
    font-size: 31px;
}

#adminUserTitle {
    margin-bottom: 20px;
    font-weight: 550;
    font-size: 30px;
    color: var(--adminTitle);
}

#adminUserTitleIcon {
    font-size: 28px;
    margin-right: 20px;
}

#adminUser {
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-color: var(--scrollbar) var(--scrollbarBackground);
    scrollbar-width: thin;
    height: 450px;
    margin: 10px 10px 30px 0px;
    padding: 0 5px 0 10px;
    text-align: left;
    font-weight: 550;
    font-size: 18px;
    color: var(--adminText);
}

#adminUserLoader {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

#adminUserRowButton {
    display: flex;
}

#adminUserHeaderIcon {
    font-size: 22px;
    margin-right: 10px;
}
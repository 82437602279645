#footer {
    height: 200px;
    background: var(--footerBackground);
}

#footerLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: first baseline;
    height: 200px;
    margin-left: 5%;
}

.footerLink {
    padding: 0 20px 0 20px;
    border-radius: 10px;
    font-weight: 550;
    font-size: 20px;
    color: var(--footerLinkText);
    text-decoration: none;
    transition: all 0.3s;
}

.footerLink:hover {
    padding: 10px 40px 10px 40px;
    background: var(--footerActiveLinkBackground);
    color: var(--footerActiveLinkText);
}

.footerLinkIcon {
    margin-right: 10px;
}

#footerRight {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 200px;
    margin-right: 5%;
}

#footerLinkedinIcon {
    margin: 0 10px 0 10px;
    font-size: 35px;
    color: var(--navSocialIcon);
    transition: all 0.3s;
}

#footerLinkedinIcon:hover {
    margin: 0 10px 0 10px;
    font-size: 40px;
    color: var(--navSocialIconActive);
}

#footerFacebookIcon {
    margin: 0 10px 0 10px;
    font-size: 30px;
    color: var(--navSocialIcon);
    transition: all 0.3s;
}

#footerFacebookIcon:hover {
    margin: 0 10px 0 10px;
    font-size: 35px;
    color: var(--navSocialIconActive);
}
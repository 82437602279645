.smartphoneSectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.smartphoneSectionLightText {
    margin: 0 0 30px 10%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

#smartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

#smartphoneSectionDarkText {
    margin: 0 10% 30px 0;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.smartphoneTextParagraph {
    margin-top: 15px;
    margin-bottom: 15px;
}

.smartphoneImage {
    border-radius: 10px;
}

.smartphoneIcons {
    margin: 50px 15% 0 15%;
}

.smartphoneSmartphoneSectionLight {
    padding: 25px 0 0 0;
    background: var(--sectionLightBackground);
}

.smartphoneSmartphoneSectionLightText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.smartphoneSmartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 50px 0 50px 0;
    background: var(--sectionDarkBackground);
}

.smartphoneSmartphoneSectionDarkText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.smartphoneImageSmartphone {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}
.videoSectionLight {
    padding: 100px 0 100px 0;
    background: var(--sectionLightBackground);
}

.videoSectionLightText {
    margin: 0 0 30px 10%;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

#videoSectionDark {
    margin: 500px 0 400px 0;
    padding: 100px 0 100px 0;
    background: var(--sectionDarkBackground);
}

#videoSectionDarkText {
    margin: 0 10% 30px 0;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.videoTextParagraph {
    margin-top: 15px;
    margin-bottom: 15px;
}

.videoImage {
    border-radius: 10px;
}

.videoIcons {
    margin: 50px 15% 0 15%;
}

.videoSmartphoneSectionLight {
    padding: 25px 0 0 0;
    background: var(--sectionLightBackground);
}

.videoSmartphoneSectionLightText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionLightText);
}

.videoSmartphoneSectionDark {
    margin: 500px 0 400px 0;
    padding: 50px 0 50px 0;
    background: var(--sectionDarkBackground);
}

.videoSmartphoneSectionDarkText {
    margin: 0 20px 50px 20px;
    text-align: justify;
    font-weight: 550;
    font-size: 18px;
    color: var(--sectionDarkText);
}

.videoSmartphoneImage {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 10px;
}
#adminUserAdd {
    display: flex;
    justify-content: left;
    margin-bottom: 0px;
    margin-left: 5%;
}

#adminUserAddForm {
    margin: 50px 5% 30px 5%;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
.inputSearch.form-control::placeholder {
    color: var(--inputSearchText);
    opacity: 1;
}

/* Internet Explorer 10-11 */
.inputSearch.form-control:-ms-input-placeholder {
    color: var(--inputSearchText) !important;
}

/* Microsoft Edge */
.inputSearch.form-control::-ms-input-placeholder {
    color: var(--inputSearchText) !important;
}

.input-group {
    margin-bottom: 30px !important;
}

.form-control:focus {
    box-shadow: 0 0 0 !important;
}

.inputSearchIcon {
    z-index: 1 !important;
    height: 40px !important;
    width: 50px !important;
    margin-left: 5% !important;
    margin-right: -50px !important;
    padding-left: 15px !important;
    border: none !important;
    border-radius: 20px 0 0 20px !important;
    background: var(--inputSearchBackground) !important;
    color: var(--inputSearchText) !important;
}

.inputSearch {
    z-index: 0 !important;
    height: 40px !important;
    /* margin-right: 5% !important; */
    padding-left: 60px !important;
    padding-right: 40px !important;
    border: none !important;
    box-shadow: 0 0 0 !important;
    border-radius: 20px !important;
    background: var(--inputSearchBackground) !important;
    color: var(--inputSearchText) !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.inputSearchClear {
    z-index: 1 !important;
    cursor: pointer;
    height: 40px !important;
    width: 50px !important;
    margin-left: -40px !important;
    margin-right: 5% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border: none !important;
    border-radius: 0 20px 20px 0 !important;
    background: var(--inputSearchBackground) !important;
    font-size: 18px !important;
    color: var(--inputSearchText) !important;
}
.accordion {
    width: 100% !important;
    margin-right: 3px;
}

.accordion-item {
    border: none !important;
    text-shadow: none !important;
    background: var(--accordionHeaderBackground) !important;
}

.accordion-button {
    background: var(--accordionHeaderBackground) !important;
    font-weight: 550 !important;
    font-size: 18px !important;
    color: var(--adminText) !important;
}

.accordion-button:hover {
    background: var(--accordionHeaderBackgroundActive) !important;
}

.accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.accordion-body {
    padding: 20px 0 50px 0 !important;
    text-align: center !important;
    background: var(--accordionBodyBackground) !important;
}